$input-background-color: rgba(0, 0, 0, .03);
$input-strip-blank-color: rgba(0, 0, 0, .5);
$input-strip-valid-color: rgba(0, 0, 0, 1);
$input-strip-invalid-color: #F00;

$input-padding-left-increment: 75;
$input-padding-y-increment: 125;
$input-margin-bottom-increment: 125;

$transition-duration: .2s;
