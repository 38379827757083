/*
    Make the reset rules
*/
@mixin reset {
    html {
        font-size: 16px;
        scroll-behavior: smooth;
    }
    
    body {
        min-height: 100vh;
        margin: 0;
    }
    
    h1, h2, h3, h4, h5, h6, p {
        margin: 0;
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: 400;
    }

    ul {
        padding: 0;
        margin: 0;
    }
    
    li {
        list-style: none;
    }

    a {
        color: #000;
        text-decoration: none;
    }

    button {
        outline: 0;
        background: none;
        border: none;
        padding: 0;
        margin: 0;
    }

    input[type="text"], textarea {
        border: none;
        outline: none;
    }
}
